
.slider {
    position: relative;
    height: 500px;
    
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }
    
    margin-inline: auto;
    //padding: 5rem 0;

    // @media (max-width: 1024px){
    //     height: 400px;
    // }

    /*@media (max-width: 480px) {
        height: 600px;
    }*/
}

.slider-wrapper {
    position:relative;
    overflow: hidden;

    .slide {
        position: absolute;
        display: flex;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        z-index: 1;
    }

    .slide .image {
        margin-bottom: 1rem;
        align-items: center;
        display: flex;
    }
}

.slide-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.slides-container {
    position:relative;
    margin-inline: auto;
}

.slide-navigation__txt {
    position: absolute;
    top: 25%;
    left: 100%;
    height: 200px;

    @media (max-width: 1024px){
        height: 150px;
    }

    @media (max-width: 767px){
        display: none;
    }

    &::after {
        content: "";
        display: table;
        clear: both;
    }

    ul {
        margin: 0;
        padding:0;
        list-style: none;
    }
    li {
        span {
            cursor:pointer;
            display: block;
            margin-bottom: 20px;
            width: 15px;
            height: 5px;
            background-color: var(--color-medium-grey);

            transition: all .5s ease;
            -webkit-transition: all .5s ease;

            .touch & {
                height: 10px;
            }

            &.active {
                width: 30px;
                background-color: var(--color-accent);
            }

        }
    }

}
