
%type {
    font-weight: normal;
    letter-spacing: 3px;
    text-transform: uppercase;
    display: block;
    //color: $grey4;
}

%cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}