
.tpl-members {

    .page-title,
    .container-fluid h1 {
        @extend h2;

        margin-bottom: 2rem;
    }

    .container .form-group {
        width: 100%;
    }

    .alert .initialism {
        display: none;
    }

    &__login .page-title {
        @extend h2;
    }

    &__login > div {
        @extend .alert;
        @extend .alert-danger;

        &.no-bg {
            background-color: transparent!important;
            color: $black!important;
            border: none!important;

            @media (max-width: 992px) {
                margin: 0!important;
                padding: 10px 0!important;
            }
        }
    }

    .invisible {
        display: none;
    }

    .alert-danger {
        padding: 10px;
    }

    button.close {
        display: none;
    }
}

// Info per
.reserved {
    font-size: 12px;
    display: none;

    .logged-in & {
        display: inline-block;
        width: 100%;
    }

    .col {
        justify-content: space-between;
        padding: 1.5rem 0;
        background-color: springgreen;
    }

    .text {
        margin-right: 2rem;
        display: inline-block;

        @media (max-width: 767px){
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }

    .btn-logout {
        font-size: 12px;
        padding: 5px 15px;
        background-color: transparent;
        color: black;
    }
}

.logged-in__px {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: springgreen;
    display: none;

    .logged-in & {
        display: inline-block;
    }
}