
.full-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.spot-evento {
    padding: 30px;
    position: relative;

    @media (max-width: 767px){
        padding: 0;
        display: block!important;
    }

    .type {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 18px;
        color: var(--color-accent);
    }

    &:before {
        content:'';
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        width: 70%;
        height: 100%;
        background-color: var(--color-dark-grey);

        @media (max-width: 767px){
            display: none;
        }
    }

    .part {
        width: 50%;

        &.text-right {
            color: var(--color-light-grey);
        }

        @media (max-width: 767px){
            width: 100%;

            &.text-right {
                text-align: left!important;
                padding: 15px;
            }
        }
    }

    &:hover .btn--primary {
        @extend .btn--primary:hover;
    }
}

.spot-product {
    height: 700px;
    position: relative;
    margin-bottom: 80px;

    @media (max-width: 992px) {
        height: auto;
        margin-bottom: 40px;
        min-height: 540px;
    }

    @media (max-width: 767px) {
        min-height: inherit;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        z-index: -1;
        width: 52%;
        height: 100%;

        @extend .bg-pattern;

        @media (max-width: 992px){
            width: 100%;
        }
    }

    &.to-left:before {
        left: 0;
    }

    &.to-right:before {
        right: 0;
    }

    .type {
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 18px;
    }

    .image {
        position: absolute;
        top: 85px;
        right: 0;
        z-index: 0;

        width: 52%;
        height: 533px;

        background-size: cover;
        background-repeat: no-repeat;

        @media (max-width: 992px){
            height: 540px;
            top: 0;
        }

        .is-editmode & {
            background-image: none!important;
        }

        img {
            display: none;

            .is-editmode & {
                display: block;
            }
        }
    }

    &.to-left .image {
        right: 0;
    }

    &.to-right .image {
        left: 0;
    }

    .catalog {
        position: absolute;
        top: -35px;
        left: 50%;
        z-index: 1;

        width: 330px;
        margin-left: -165px;

        @media (max-width: 1024px){
            width: 250px;
            margin-left: -125px;
        }

        @media (max-width: 992px){
            position: static;
            width: 50%;
            margin-left: 0;
            margin-bottom: 2rem;
        }

        img {
            box-shadow: 7px 7px 0 0 #e6e6e6;
        }
    }

    .text {
        @media (max-width: 992px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .btn--link {
        clear: left;
        float: left;
    }
}

.spot-icontip {
    min-height: 280px;

    @media (max-width: 992px){
        min-height: inherit;
        margin: 1rem 0;
        text-align: center;

        .media {
            width: 100%;
            display: block!important;
        }

        .icon {
            margin-right: 0!important;
        }
    }

    i {
        font-size: 60px;
    }

    &.not-text {

        background-color: $grey2;

        .icon i {
            position: absolute;
            left: 50%;
            top: 50%;
            font-size: 70px;
            margin-left: -35px;
            margin-top: -35px;
            //color: #fff;
        }
    }
}

.spot-default {
    //min-height: 280px;

    &.pt-0 {
        padding-top: 0!important;
    }

    &.py-0 {
        padding-top: 0!important;
        padding-bottom: 0!important;
    }

    &.pb-0 {
        padding-bottom: 0!important;
    }
}

.spot-txt,
.spot-txt-img {
    position: relative;

    @media (max-width: 767px){
        margin-bottom: 2rem;
    }

    .image {
        width: 100%;
        height: 270px;
        border: 1px solid var(--color-dark-grey);
        background-color: var(--color-light-grey);
        margin-bottom: 14px;

        @extend %cover;

        @media (max-width: 767px){
            height: 180px;
        }
    }

    &:hover .btn--primary {
        @extend .btn--primary:hover;
    }

    figure {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #cacaca;
        position: relative;

        img {
            width: 100%;
        }

        .list-spot--cerniere & {
            padding: 0;
        }
        
    }

    h5 {
        font-size: var(--fs-2);
    }
}

// badge 
.spot-txt-img__badge {
    color: white;
    text-transform: uppercase;
    padding: 4px 10px;
    line-height: 1.2;

    position: absolute;
    bottom: 0;
    left: 0;

    .is-koblenz & {
        background-color: var(--color-accent);
    }
    .is-krona & {
        background-color: var(--color-accent);
    }

    &.coming_soon {
        color: white;
        background-color: black;
        font-weight: bold;
    }
}

:root {
    --color-info: var(--color-medium-grey);
}

.spot__info {
    font-size: 10px;
    line-height: 1.4;

    label,
    span {
        display: block;
    }

    .info-portata {

        &:before {
            content: '\f067';
            font-family: 'Font Awesome 5 Pro';
            margin-right: 5px;
            color: var(--color-accent);

            .is-koblenz & {
                color: var(--color-accent);
            }
        }
    }

    > p {
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 10px;

        strong {
            font-weight: bold;    
            color: var(--color-info);
        }
    }
}

.spot__cert {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-inline-start: 0;
    padding-left: 0;
    margin-top: 0px;
    margin-left: -10px;

    .is-product & {
        margin-left: -30px;
        margin-top: auto;
    }
    
    > li {
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background-color: var(--color-light-grey);
        margin-left: 10px;
        margin-top: 10px;

        > img {
            max-width: 100%;
            width: auto;
            height: 23px;
        }

        .is-product & {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 30px;
            margin-bottom: 30px;
            width: 150px;
            height: 150px;
            line-height: initial;

            img {
                height: 80px;
            }
        }
    }
}

.spot-cat {
    position: relative;

    @media (max-width: 767px) {
        display: inline-block;
        width: 100%;
    }

    .type {
        @extend %type;
        font-size: var(--fs--1);
        margin-top: 20px;

        .is-arkilab & {
            margin-block: 0.5rem;
        }
    }

    .date {
        .is-arkilab & {
            color: var(--color-medium-grey);
            font-size: var(--fs--1);
            display: inline-block;
            margin-block-end: 1rem;
        }
    }

    .image {
        width: 200px;
        height: 280px;
        background-color: var(--color-light-grey);

        @extend %cover;
        background-position: left center;

        .is-arkilab & {
            width: auto;
            height: auto;
        }

        @media (max-width: 992px){
            width: 150px;
            height: 200px;
        }

        @media (max-width: 767px){
            width: 100%;
            height: 180px;
        }
    }

    &:hover .btn--primary {
        @extend .btn--primary:hover;
    }
    &:hover .btn-black {
        @extend .btn-black:hover;
    }

    .media-body h2 {
        font-size: var(--fs-3);
        word-break: break-word;
    }

    .media-body h4 {
        font-size: var(--fs-1);
        word-break: break-word;
        font-weight: 600;
    }

    .media-body p {
        // text-transform: lowercase;
        margin-block-end: 0.5rem;

        // &:first-letter {
        //     text-transform: uppercase;
        // }
    }
}

.spot-card {
    background-color: $grey7;
    padding: 35px 30px;
    color: var(--color-dark-grey);
    //min-height: 234px;
    //margin-bottom: 2rem;
    height: 100%;

    @media (max-width: 767px){
        min-height: inherit;
    }

    .type {
        @extend %type;
        font-size: 14px;
        margin-bottom: 22px;
    }

    .info {
        line-height: 1.63;
    }

    .email,
    .tel {
        color: var(--color-dark-grey);
        display: block;
    }
}