
// Dialog
.modal-dialog {

    @media (max-width: 767px){
        max-width: inherit!important;
        width: 100vw;
        height: 100vh;
        //height: auto;
        margin: 0;
        background-color: white;

        .modal-content {
            padding: 1rem;
        }
    }
}

.modal-content {
    border-radius: 0;
    border: none;
}

.modal-header {
    border-bottom: none;

    .close {
        position: absolute;
        top: 25px;
        right: 30px;
        padding: 0;
        font-weight: normal;
    }
}

.modal {

    &__form .form-control {
        margin-bottom: 1rem;
    }

    &__form .privacy {
        font-size: 11px;

        a {
            font-weight: bold;
            color: black;
            text-decoration: underline;
        }

        label {
            margin-right: 30px;
        }

        input[type="radio"] {
            margin-right: 10px;
        }
    }

    &--login .btn {
        width: 100%;

        &.register {
            margin-top: 1rem;
        }
    }

    &--login .modal-dialog {
        //max-width: 370px!important;
        max-width: 960px;
    }

    .modal-content {
        padding: 2rem;
    }
}

.modal--video .modal-dialog {
    max-width: 600px;

    .modal-title {
        margin-bottom: 0;
        padding: 1.3rem;
        padding-bottom: .3rem;
    }

    .close {
        top: 20px;
        right: 23px;
        z-index: 1000;
    }
}
.modal--video .modal-body {
    padding: 1.3rem;
}
.modal--video .modal-header,
.modal--video .modal-content {
    padding: 0;
}

#modalIssuu .modal-body {
    @media (max-width: 767px){
        padding: 0;
        padding-top: 1rem;
    }
}

// Info modal prodotto
.modal--info {
    .modal-dialog {
        max-width: 80vw;
    }

    .modal-header {
        padding: 0 30px;
    }

    &__pad {
        padding: 0 15px;
    }

    .modal-body {
        overflow: hidden;
        height: 95vh;

        iframe {
            height: 100%;
        }
    }

    .modal-title {
        @media (max-width: 992px){
            margin-top: 30px;
        }
    }
}

.modal--login {
    .modal-body {
        padding: 0;
    }

    .login__reset {
        //margin-top: 10px;
        padding-top: 15px;
        //border-top: 1px solid #f0f0f0;

        > p {
            margin-bottom: 0;
        }
    }
}

#modalIssuu .modal-content {
    padding: 0;
}

@media (max-width: 767px) {
    .modal-dialog .modal-content {
        padding: 1rem!important;
    }
}

