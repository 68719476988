
.gallery {

    .carousel {
        height: 100%;
    }

    .carousel-cell {
        width: 100%;
        height: 100%;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .flickity-page-dots {
        display: none;
    }
}

.carousel-mobi {
    //height: 500px;
    background-color: $grey2;

    .carousel-cell {
        width: 100%;
        height: 100%;
        text-align: center;
    }
}

.gallery,
.carousel-mobi {

    .flickity-button {
        top: auto;
        bottom: 0px;

        @media (max-width: 576px) {
            bottom: -15px;
        }

        &:before {
            font-family: "Font Awesome 5 Pro";
            font-weight: lighter;
            font-size: 30px;
            color: white;
        }

        svg {
            display: none;
        }

        &.previous {
            left: auto;
            right: 100px;

            @media (max-width: 576px){
                right: 60px;
            }

            &:before {
                content: '\f177';
            }
        }

        &.next {
            right: 30px;

            @media (max-width: 576px) {
                right: 15px;
            }

            &:before {
                content: '\f178';
            }
        }
    }
}

.flickity-page-dots {
    @media (max-width: 767px){
        display: none;
    }
}
