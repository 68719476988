:root {
    --aspect-ratio: 16/9;
    --aspect-ratio-2: 4/3;
}

.cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    img {
        display: none;

        @media (max-width: 992px){
            display: inline-block;
        }
    }

    &--16_9 {
        padding-top: calc(100% / ( var(--aspect-ratio)));
    }

    &--4_3 {
        padding-top: calc(100% / (var(--aspect-ratio-2)));
    }

    &--50 {
        height: 50vh;
    }
    &--75 {
        height: 75vh;
    }
    &--100 {
        height: 100vh;
    }

    // responsive
    @media (max-width: 992px){
        background-image: none;
        height: auto;
    }
}