.site-footer {
  padding: 45px 0 !important;
  color: var(--color-light-grey);

  h5 {
    font-size: var(--fs-1);
    font-weight: 700;
  }

  &__menu {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: var(--fs--1);
      line-height: 1.33;

      li + li {
        margin-top: 8px;
      }

      a {
        color: var(--color-light-grey);

        @media screen and(hover: hover) {
          &:hover {
            text-decoration: none;
            color: var(--color-accent);
          }
        }
      }
    }
  }

  .is-product & {
    clear: left;
  }

  &__colophon {
    border-block-start: 1px solid var(--color-light-grey);
    font-size: var(--fs--1);

    ul {
      gap: 8px;

      @media screen and (min-width: 768px) {
        gap: 24px;
      }

      @media screen and(hover: hover) {
        a:hover {
          text-decoration: none;
          color: var(--color-accent);
        }
      }
    }
  }

  &--landing {
    .site-footer__colophon {
      border: none;
    }
  }
}

.social-list {
  li + li {
    margin-left: 42px;
  }

  a {
    > i {
      font-size: var(--fs-1);
      color: var(--color-light-grey);
    }

    @media screen and (hover: hover) {
      &:hover i {
        color: var(--color-accent);
      }
    }
  }
}
