:root {
    --color-accent: #C7F403;
    --color-light-grey: #F5F5F5;
    --color-dark-grey: #303134;
    --color-medium-grey: #AFB0B2;
    // --color-krona: #DE4D4A;
    // --primary-font: "Helvetica", Arial, sans-serif;
}

* {
    outline: none;
}

html {
    &.is-hidden {
        overflow: hidden;
        height: 100vh;
    }
}

body {
    overflow-x: hidden;
    font-family: var(--ff-standard);
    font-weight: 400 !important;
    font-size: var(--fs-0) !important;
    line-height: 1.6;
    scroll-behavior: smooth;

    &.menu-is-open {
        overflow: hidden;
        height: 100vh;
    }

    &.is-piazza {

    }
}

/*@media (max-width: 1024px) {
    html.no-touch,
    .no-touch body {
        min-width: 1024px;
    }
}*/

ul {
    // padding-left: 0;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

a {
    outline: none;
}

b,strong {
    font-weight: 700!important;
}

body::-webkit-scrollbar {
    width: .3em!important;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1)!important;
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey!important;
    outline: 1px solid slategrey!important;
}

