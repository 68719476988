
.toolbox-gallery {
    margin: 2rem 0;
}

.toolbox-slide-columns {
    .row {
        display: inline-block;
    }

    .column {
        float: left!important;
    }
}

.toolbox-spacer {

    > span {
        display: block;
    }

    .spacer-50 {
        height: 50px;

        @media (max-width: 767px){
            height: 30px;
        }
    }

    .spacer-none {
        height: 1rem;
    }
}

.toolbox-video {
    @extend .embed-responsive;
    @extend .embed-responsive-16by9;

    .poster-overlay {
        @extend .embed-responsive-item;
        margin: 0;
        background-size: cover;
    }

    .poster-overlay .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 70px;
        margin-left: -35px;
        margin-top: -35px;
        color: #fff;

        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;

        &:before {
            content: "\f144";
        }
    }
}

.toolbox-element {
    margin-bottom: 2rem;

    &.toolbox-spacer {
        margin-bottom: 0;
    }
}

.toolbox-gallery-folder {
    .gallery-folder-item {
        margin-bottom: 30px;

        > a {
            opacity: .9;

            transition: opacity .25s ease-in-out 0s;
            -webkit-transition: opacity .25s ease-in-out 0s;
        }

        > a:hover {
            opacity: 1;
        }
    }
}
