
.megamenu {
    position: absolute;
    top: 0;
    z-index: 35;
    width: 100vw;
    height: 100vh;
    background-color: white;

    //transition: transform .25s ease-in 0s;
    //transform: translateY(-100%);

    opacity: 0;

    -webkit-animation: slide-top 0.25s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    animation: slide-top 0.25s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;

    //overflow: hidden;

    padding-top: 138px;
    overflow: auto;

    /*@media (max-height: 1024px) {
        overflow: auto;
    }*/

    @media (max-width: 767px) {
        overflow: auto;
        padding-top: 83px;
    }

    &.is-open {
        //transform: translateY(0);
        opacity: 1;

        -webkit-animation: slide-bottom 0.35s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
        animation: slide-bottom 0.35s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    }

    .wrap {
        max-width: 1580px;
    }

    .media {

        @media (max-width: 992px){
            padding: 0 2rem;
            margin-bottom: 2rem;
        }

        @media (max-width: 767px){
            padding: 0 1rem;
            display: block;
        }

        img {
            width: 100px;

            @media (max-width: 1200px) {
                width: 70px;
            }

            @media (max-width: 767px){
                margin-bottom: 1rem;
            }
        }
    }

    .media-body h3 {
        margin-bottom: 20px;

        > a {
            color: black;
        }
    }

    &__cont {
        padding-top: 3.5vh;

        @media (max-width: 767px) {
            padding-top: 40px;
        }
    }

    &__bottom {
        //position: fixed;
        //bottom: 0;
        width: 100%;

        @media (max-width: 992px) {
            position: static;
        }

        /*@media (max-height: 940px) {
            position: static;
        }*/
    }

    .lang {
        padding: 30px 0;

        .logged-in & {
            padding: 20px 0;
        }

        @media (max-width: 1200px) {
            padding: 2rem 0;
        }

        @media (max-width: 767px) {
            padding: 20px 0;
        }
    }

    &__list {
       list-style: none;

       li a {
           font-family: $headings-font-family;
           color: #000;
       }

       &.small li {
           line-height: normal;
           padding-bottom: 20px;
           padding-left: 25px;

           position: relative;

           &:before {
               content: '';
               width: 15px;
               height: 4px;
               //background-color: $grey5;

               position: absolute;
               top: 10px;
               left: 0;
           }

           > a {
               font-size: 1.25rem;
               font-weight: 600;
           }
        }

        &.ko li:before {
            background-color: var(--color-accent);
        }
        &.kr li:before {
            background-color: var(--color-accent);
        }

        &.big {
            margin: 0 2rem;
        }

        &.big li {
            line-height: normal;
            padding-bottom: 22px;

            > a {
                font-size: 1.75rem;
                color: var(--color-dark-grey);
                font-weight: 500;
            }
        }
    }
}

.assistance {
    padding: 40px 0;
    padding-bottom: 30px;

    .logged-in & {
        padding: 36px 0;
    }

    @media (max-width: 1200px) {
        padding: 2rem 0;
    }

    @media (max-width: 767px) {
        padding: 25px 0;
    }

    &__el {
        max-width: 640px;
        margin: auto;

        @media (max-width: 992px) {
            max-width: 100%;
            padding: 0 2rem;
            text-align: center;
        }
    }

    h4 {
        display: inline-block;
        line-height: 2.4;

        @media (max-width: 767px) {
            display: block;
            line-height: inherit;
            margin-bottom: 2rem;
        }
    }

    .btn {
        float: right;
        padding: 0 50px;
        height: 64px;
        line-height: 64px;
        text-transform: uppercase;
        font-size: .9rem;
        letter-spacing: 2px;
        background-color: var(--color-dark-grey);
        border: none;

        @media (max-width: 767px){
            padding: 0 30px;
            float: none;
            clear: left;
        }
    }
}

.lang {
    .nav a {
        color: black;
        font-size: 14px!important;

        &:hover {
            opacity: .8;
        }
    }

    .nav .current a {
        font-weight: bold;
    }

    .nav-link {
        @media (max-width: 992px) {
            padding: .5rem .4rem;
        }
    }
}

.aree-riservate {
    padding: 20px 0;
    border-top: 2px solid var(--color-light-grey);

    &__list {
        margin-bottom: 0;
    }

    &__list li {
        display: inline-block;
        padding: 0 15px;

        &:first-child {
            padding-left: 0;
        }

        &:lst-child {
            padding-right: 0;
        }

        > a {
            color: var(--color-dark-grey);
            font-weight: 500;
            font-family: $headings-font-family;
        }
    }
}

.menu-item {
    // Item in evidenza
    &--pinned > a {
        position: relative;

        &:after {
            position: absolute;
            left: 0;
            top: -13px;
            content: 'NEW';
            color: var(--color-accent);
            font-size: 10px;
            letter-spacing: 1.3px;
        }
    }
}