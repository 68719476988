.home-hero {
  height: 100vh;
  position: relative;
  display: grid;
  align-items: center;

  &::after {
    content: '';
    display: block;
    position: absolute;
    inset: 0;
    background-color: hsla(0, 0%, 0%, 0.2);
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .text {
    z-index: 2;
    color: var(--color-light-grey);

    .label {
      text-transform: uppercase;
      font-size: var(--fs-0);
      margin-block-end: 16px;
    }
    
    h1 {
      max-width: 720px;
      text-wrap: balance;
      font-size: var(--fs-4);
      line-height: 1.2;
      font-weight: 600;
      margin-block-end: 48px;
    }
  }
}