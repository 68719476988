
.breadcrumbs {
    font-size: var(--fs-0);
    font-family: var(--ff-standard);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 10px;

    @media (max-width: 767px){
        margin-bottom: 0;
        font-size: 14px;
    }

    .is-product & {
        font-size: 16px;
    }

    a {
        color: var(--color-dark-grey);
        text-transform: capitalize;

        a:hover {
            opacity: .8;
            text-decoration: none;
        }
    }

    span {
        color: var(--color-medium-grey);
        margin: 0 10px;

        &:last-of-type(1) {
            display: none;
        }
    }
}