.contatti-commerciali-list {
  display: grid;
  gap: 2rem;
  
  .list-sedi {
    display: grid;
    gap: 1rem;

    .item {
      background-color: var(--color-light-grey);
      padding: 1rem;
      display: none;
      margin-block-start: 2rem;

      * {
        margin: 0;
      }

      h4 {
        font-weight: 700;
        font-size: var(--fs-2);
        margin-block-end: 1rem;
      }

      .data {
        margin-block-end: 2.5rem;

        p {
          margin: 0;

          a {
            color: var(--color-dark-grey);
          }
        }
      }
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(3, 1fr);
      
      .item {
        padding: 2.5rem;
        grid-column: span 2;
      }
    }
  }

  .list-agenti {
    display: grid;
    gap: 1rem;

    .item {
      padding: 1rem;
      display: none;
      border: 1px solid var(--color-medium-grey);

      * {
        margin: 0;
      }

      h4 {
        font-weight: 700;
        font-size: var(--fs-2);
        margin-block-end: .25rem;
      }

      .role {
        font-size: var(--fs-1);
        margin-block-end: 1rem;
        line-height: 1.1;
      }

      .data {
        margin-block-end: 1rem;

        p {
          margin: 0;

          a {
            color: var(--color-dark-grey);
          }
        }
      }

      .aree {
        font-size: var(--fs--1);

        .title {
          text-transform: uppercase;
          margin-block-end: .5rem;
          text-decoration: underline;
          text-decoration-color: var(--color-accent);
          text-decoration-thickness: 2px;
          text-underline-offset: .25rem;
        }
      }
    }

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
      
      .item {
        padding: 2.5rem;
      }
    }
  }
}