.fullwidth-text {
  padding-block: 32px;

  @media screen and (min-width: 768px) {
    padding-block: 64px;
  }

  .text {
    text-align: center;
    max-width: 65ch;
    margin-inline: auto;

    h2 {
      font-size: var(--fs-3);
      line-height: 1.16;
      font-weight: 600;
    }

    p {
      font-size: var(--fs-0);
      margin: 0;
    }
  }
}