
/*\
 * Restore Bootstrap 3 "hidden" utility classes.
\*/

/* Breakpoint XS */
@media (max-width: 575px)
{
    .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
    .hidden-xs-up,
    .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
    .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
    .hidden-xs-up, .hidden-sm-up,
    .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
    .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up,
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
    {
        display: none !important;
    }
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
    .hidden-lg-down, .hidden-xl-down,
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up,
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
    {
        display: none !important;
    }
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
    .hidden-xl-down,
    .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up,
    .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
    {
        display: none !important;
    }
}

.color-krona,
.color-primary {
    color: var(--color-accent);
}

.color-koblenz,
.color-secondary {
    color: var(--color-accent);
}

// Bootstrap

// @media (min-width: 1200px){
//     .container {
//         max-width: 960px!important;
//     }
// }

// Slick slider
.slick-prev:before, .slick-next:before {
    color: black;
}
.slick-dots li button:before {
    font-size: 10px;
}

.slick-slider .slick-slide {
    text-align: center;

    img {
        text-align: center;
        margin: auto;
    }
}

.alert {
    border-radius: 0!important;
}

// Scroll top

.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 15px;
    background-color: #888;
    opacity: 0;

    transition: opacity .25s ease-in-out 0s;
    -webkit-transition: opacity .25s ease-in-out 0s;

    @media (min-width: 767px){
        bottom: 40px;
        right: 40px;
    }

    &.visible {
        opacity: 1;
    }

    i {
        font-size: 22px;
        color: white;
    }
}

.bg-dark {
    background-color: var(--color-dark-grey) !important;
}


