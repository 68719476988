:root {
    --border-color: #d0d0d0;
}

.filters {
    margin-block: 35px;
    padding: 13px 0 35px;

    .is-piazza & {
        margin-top: 0;
    }

    //border-top: 2px solid #cacaca;
    border-bottom: 2px solid #cacaca;

    //.is-krona & {
    //    display: none;
    //}

    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 1rem;
        margin-bottom: 0;
        padding-inline-start: 0;

        @media (max-width: 767px) {
            height: auto;
            line-height: inherit;
        }
    }

    li {
        list-style: none;
        display: inline;

        @media (max-width: 767px){
            display: inline-block;

            &:first-child .filters__item {
                margin-left: 0;
            }
        }
    }

    .label {
        text-transform: uppercase;
        font-size: var(--fs--1);

        .store-locator & {
            display: block;
        }

        @media (max-width: 767px){
            display: block;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__item {
        @extend .btn;
        @extend .btn--black-outlined;

        &:first-child {
            margin-left: 0;
        }

        &.active {
            @extend .btn--black-outlined:hover;
        }

        @media (max-width: 767px) {
            margin-left: 0;
            margin-right: 10px;
            margin-bottom: 5px;
        }

        .is-arkilab & {
            position: relative;

            &:before {
                position: absolute;
                bottom: 0;
                left: 0;

                content: '';
                width: 100%;
                height: 3px;
                overflow: hidden;
                background-color: transparent;

                //transition: height .25s ease-in-out 0s;
            }
        }
    }

    // New version
    .ext-custom-select.countries {
        display: none;

        &.visible {
            display: inline-block;
        }
    }

    &--koblenz {
        position: relative;

        > label[for=filters] {
            position: absolute;
            top: -30px;
            left: 0;
            font-size: var(--fs--1);
            text-transform: uppercase;
            margin: 0;
            padding: 0;            
        }
    }

    // New filter select
    &__select {
        position: relative;
        padding: 0 15px;
        max-width: 100%;
        flex-basis: 100%;
        margin-bottom: 15px;

        label {
            font-size: var(--fs--1);
            text-transform: uppercase;
            line-height: 1.1;
        }

        &:last-child {
            margin-bottom: 0;
        }

        @media (min-width: 480px){
            max-width: 33.333%;
            flex-basis: 33.333%;
            margin-bottom: 0;
        }

        &:after {
            position: absolute;
            bottom: 50%;
            right: 35px;
            transform: translateY(100%);
            display: inline-block;
            content: '\f078';
            font-family: 'Font Awesome 5 Pro';
        }
    
        select {
            width: 100%;
            height: 45px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            appearance: none;
            border: 1px solid var(--border-color);
            cursor: pointer;
            background-color: white;
        }
    }
}

.filters__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #cacaca;
    margin-bottom: 15px;

    label {
        font-size: var(--fs-0);
    }

    .filters__reset {
        display: flex;
        align-items: center;
        column-gap: 10px;
        font-weight: 400;
        color: black;
        margin-bottom: .5rem;
        cursor: pointer;
        opacity: .9;

        &:hover {
            opacity: 1;
        }

        &:after {
            content: 'x';
        }
    }
}

.flex-filters {
    display: flex;
    flex-direction: column;
    margin: 0 -15px;

    @media (min-width: 480px){
        flex-direction: row;
    }
}

.store-list .store-item,
.list-filter .item-filter {
    margin-bottom: 2rem;
}

.store-list__msg {
    width: 100%;
    padding: 50px 0;
    padding-bottom: 75px;
    text-align: center;
    font-size: var(--fs-1);
    display: none;
}

.select-continents option {
    display: none;
}
.select-continents option:nth-child(1) {
    display: block;
}

.is-arkilab .filters__item.active {
    background-color: transparent;

    &#all {
        background-color: rgba(var(--color-dark-grey),.8);
    }

    &:before {
        height: 100%;
        z-index: -1;
    }
}

// Filters store / agenti
.filters.for-store .label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0px;
    margin-bottom: 20px;
    border-bottom: 2px solid #cacaca;
}
.filter__reset-country {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: black;
    margin-bottom: .5rem;
    cursor: pointer;
    opacity: .9;
    margin-left: 30px;
    cursor: pointer;

    &:hover {
        opacity: 1;
    }

    &:after {
        margin-left: 10px;
        content: 'x';
    }
}