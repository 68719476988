.home-banner {
  display: grid;
  color: var(--color-light-grey);

  > * {
    grid-row: 1/-1;
    grid-column: 1/-1;
  }
  
  > picture img {
    object-fit: cover;
    object-position: center;
    aspect-ratio: 21/9;
    width: 100%;

    @media screen and (orientation: portrait) and (max-width: 640px)  {
      aspect-ratio: 4/5;
    }
  }

  > .container {
    display: grid;
    align-content: center;
    position: relative;
  }

  &__content {
    @media screen and (min-width: 992px) {
      width: 50%;
      margin-inline-start: auto;
    }

    .arrow-link {
      display: inline-block;
    }

    > * + * {
      margin-block: 20px 0;
    }

    h2 {
      font-size: var(--fs-3);
      line-height: 1.2;
      font-weight: 600;
      text-wrap: balance;
    }

    p {
      font-size: var(--fs-0);
    }
  }
}