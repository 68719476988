:root {
    --border-color-img: var(--color-light-grey);
}
.img-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 30px;
    margin-left: -30px;

    &__item {
        color: var(--color-dark-grey);
        position: relative;
        flex: 0 0 calc(50% - 30px);
        max-width: calc(50% - 30px);
        margin-left: 30px;

        @media (min-width: 767px) {
            flex: 0 0 calc(25% - 30px);
            max-width: calc(25% - 30px);
        }

        /*@media (max-width: 767px){
            display: flex;
            flex-direction: column;
            align-items: center;
        }*/

        a {
            position: relative;
            display: block;
            color: var(--color-dark-grey);

            &:hover {
                text-decoration: none;
            }
        }

        .svg {
            position: absolute;
            top: 15px;
            right: 15px;
        }
    }

    // figure 4 all images
    figure {
        display: flex;
        align-items: center;
        justify-content: center;
        
        border: 1px solid var(--border-color-img);
        margin-bottom: 1rem;

        img {
            width: auto;
            height: auto;
            max-height: 200px;
        }
    }

    .text-grey {
        color: var(--color-medium-grey);
        display: block;
    }

    // 3 colonne accessori
    /*&.cols-3 > * {
        flex: 0 0 calc(50% - 30px);
        max-width: calc(50% - 30px);

        @media (min-width: 767px){
            flex: 0 0 calc(33.333% - 30px);
            max-width: calc(33.333% - 30px);
        }
    }*/

    &__box {
        color: black;
        
        span + span {
            margin-top: 5px; 
        }
        span {
            font-size: 14px;
            display: inline-block;
            line-height: 1.24;
        }
    }
}

// colonnizzato
.img-list--columns {
    > * {
        flex: 0 0 calc(50% - 30px);
        max-width: calc(50% - 30px);
        margin-left: 30px;

        @media (min-width: 992px){
            flex: 0 0 calc(25% - 30px);
            max-width: calc(25% - 30px);
        }

        @media (min-width: 1200px){
            flex: 0 0 calc(16.66667% - 30px);
            max-width: calc(16.66667% - 30px);
        }
    }

    figure > img.with-height {
        max-width: 150px;
        width: auto;
        height: auto;
        
        @media (min-width: 992px){
            max-height: 120px;            
        }
    }
}