@font-face {
  font-family: 'NotoSans';
  src: url('../fonts/NotoSans-Regular.woff2') format('woff2'),
       url('../fonts/NotoSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'NotoSans';
  src: url('../fonts/NotoSans-Semibold.woff2') format('woff2'),
       url('../fonts/NotoSans-Semibold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans-Regular.woff2') format('woff2'),
       url('../fonts/WorkSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans-SemiBold.woff2') format('woff2'),
       url('../fonts/WorkSans-SemiBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Quilon';
  src: url('../fonts/Quilon-Regular.woff2') format('woff2'),
       url('../fonts/Quilon-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Quilon';
  src: url('../fonts/Quilon-Medium.woff2') format('woff2'),
       url('../fonts/Quilon-Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// Utopia --> Min Viewport: width 320px, fontsize: 14px, typescale: 1.2 / Max Viewport: width 1140px, fontsize: 16px, typescale: 1.333
:root {
  /* Step -2: 9.72px → 9.00px */
  --fs--2: clamp(0.56rem, calc(0.63rem + -0.09vw), 0.61rem);

  /* Step -1: 11.67px → 12.00px */
  --fs--1: clamp(0.73rem, calc(0.72rem + 0.04vw), 0.75rem);

  /* Step 0: 14.00px → 16.00px */
  --fs-0: clamp(0.88rem, calc(0.83rem + 0.24vw), 1.00rem);

  /* Step 1: 16.80px → 21.33px */
  --fs-1: clamp(1.05rem, calc(0.94rem + 0.55vw), 1.33rem);

  /* Step 2: 20.16px → 28.43px */
  --fs-2: clamp(1.26rem, calc(1.06rem + 1.01vw), 1.78rem);

  /* Step 3: 24.19px → 37.90px */
  --fs-3: clamp(1.51rem, calc(1.18rem + 1.67vw), 2.37rem);

  /* Step 4: 29.03px → 50.52px */
  --fs-4: clamp(1.81rem, calc(1.29rem + 2.62vw), 3.16rem);

  /* Step 5: 34.84px → 67.34px */
  --fs-5: clamp(2.18rem, calc(1.38rem + 3.96vw), 4.21rem);

  --ff-standard: 'WorkSans', 'NotoSans', sans-serif;
  --ff-spacial: 'Quilon', 'NotoSans', sans-serif;
}

