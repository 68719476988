
:root {
    --color-label: var(--color-medium-grey);
    --color-icon: var(--color-medium-grey);
}

.file-cont {
    label {
        // font-size: var(--fs-1);
        font-weight: bold;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        color: var(--color-label);
        margin-bottom: 10px;
    }

    &__list {
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        row-gap: 5px;

        > * {
            flex: 0 0 100%;
            max-width: 100%;

            @media (min-width: 767px){
                flex: 0 0 50%;
                max-width: 50%;
            }

            @media (min-width: 992px){
                flex: 0 0 33.333%;
                max-width: 33.333%;
            }
        }

        a {
            color: black;
            display: flex;
            column-gap: 10px;
            padding-right: 5px;
            align-items: center;
            opacity: .8;
            transition: opacity .25s;
        }

        a:hover {
            text-decoration: none;
            opacity: 1;
        }

        i {
            color: var(--color-icon);
        }
    }
}

// 2 columns
.file-cont__list.two-col {
    > * {
        @media (min-width: 992px){
            flex: 0 0 33.333%;
            max-width: 33.333%;

            .in-column & {
                flex: 0 0 50%;
                max-width: 50%;
            }
        }
    }
}