
.no-padding {
    padding: 0!important;
}

.no-margin {
    margin: 0!important;
}

.no-disc {
    list-style: none;
}

.cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.is-hide {
    opacity: 0;
}


.mb-90 {
    margin-bottom: 90px;

    @media (max-width: 992px){
        margin-bottom: 45px;
    }
}

.mb-60 {
    margin-bottom: 60px;

    @media (max-width: 992px){
        margin-bottom: 35px;
    }
}

.p-100 {
    padding: 100px 0;

    @media (max-width: 992px){
        padding: 50px 0;
    }
}

.hidden {
    display: none!important;
}

// Margin options
.mt-md {
    margin-top: 60px!important;
}
.my-md {
    margin-top: 60px!important;
    margin-bottom: 60px!important;
}
.mb-md {
    margin-bottom: 60px!important;
}
.mb-sm {
    margin-bottom: 30px!important;
}
.m-auto {
    margin-left: auto;
    margin-right: auto;
}

// Padding
.pt-md {
    padding-top: 60px;
}
.pb-md {
    padding-bottom: 60px;
}
.py-md {
    padding-bottom: 60px;
    padding-top: 60px;
}

// Borders
.border-colored {
    border-bottom: 3px solid var(--color-light-grey);
    
    .is-krona & {
        border-color: var(--color-accent);
    }

    .is-koblenz & {
        border-color: var(--color-accent);
    }
}
.border-colored-top {
    @extend .border-colored;

    border-bottom: none;
    border-top: 3px solid var(--color-light-grey);
}

// Text
.text-columns {
    display: flex;
    flex-direction: column;
    //row-gap: 2rem;

    > * {
        flex: 0 0 100%;
        max-width: 100%;
    }

    p {
        line-height: 2;
    }

    ul {
        margin-left: 15px;
        
        li {
           line-height: 1.4; 
           margin-bottom: 10px;
        }
    }
}

/* ----------------------------------------------
 * Generated by Animista on 2018-10-4 14:40:41
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bottom
 * ----------------------------------------
 */
@keyframes slide-bottom {
    0% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}