
.catalogo {
    padding: 3rem;
    background-image: url(../images/kk_pattern.png);
    background-size: 170px;
    background-repeat: repeat;
    background-color: #f8f8f8;
    margin-bottom: 3rem;

    position: relative;
    z-index: 0;

    @include media-breakpoint-up(md) {
        background-image: none;
        background-color: white;
        padding: 0;
    }

    &__text {
        @include media-breakpoint-up(md) {
            background-image: url(../images/kk_pattern.png);
            background-size: 170px;
            background-repeat: repeat;
            padding: 10vw 2vw;
            padding-right: 14vw;
            width: 62vw;

            .t-right & {
                float: right;
                padding-right: 2vw;
                padding-left: 14vw;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 52vw;
            padding-left: 4vw;
            padding-right: 16vw;

            .t-right & {
                padding-right: 4vw;
                padding-left: 16vw;
            }
        }

        a.btn {
            width: 100%;
            max-width: 400px;
        }

        a.btn + a.btn {
            margin-block-start: 15px;
        }

        &__inner {
            background-color: white;
        }
    }


    &__copertina {
        display: none;

        img {
            box-shadow: 7px 7px 0 0 #e6e6e6;
        }

        @include media-breakpoint-up(sm) {
            display: inline-block;
            margin-bottom: 1rem;
            width: 30vw;
        }

        @include media-breakpoint-up(md) {
            width: 20vw;
            position: absolute;
            top: -35px;
            left: 60%;
            margin-left: -10vw;
            z-index: 20;

            .t-right & {
                left: 40%;
            }
        }

        @include media-breakpoint-up(lg) {
            left: 50%;

            .t-right & {
                left: 50%;
            }
        }
    }

    &__img {
        display: none;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-up(md) {
            display: inline-block;
            width: 42vw;
            height: 450px;
            position: absolute;
            top: 50%;
            right: 0;
            margin-top: -225px;
            z-index: 10;

            .t-right & {
                left: 0;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 52vw;
        }
    }
}