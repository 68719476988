.home-spot {
  padding-block-end: 32px;

  @media screen and (min-width: 768px) {
    padding-block-end: 64px;
  }

  .label {
    margin-bottom: 20px;
    font-size: var(--fs-0);
    line-height: 1;
    letter-spacing: calc(var(--fs-0) * 0.2);
  }

  .home-card {
    h3 {
      font-size: var(--fs-1);
      font-weight: 600;
      margin-block: 20px 10px;
    }

    p {
      font-size: var(--fs-0);
    }

    @media (hover: hover) {
      figure {
        overflow: hidden;
      }
      img {
        transition: transform .2s ease-out;
      }
      img:hover {
        transform: scale(1.05);
      }
    }
  }
}