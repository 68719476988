
.hero {
  .is-arkilab & {

    .breadcrumbs span {
      color: var(--color-medium-grey);
    }


    .breadcrumbs a {
      //color: white;
    }
  }
}

.content-form {
  h5 {
    margin-bottom: 30px;
  }

  .radio-privacy {
    .form-check-label {
      font-size: 14px;
    }
  }

  &__compile {
    .btn-download {
      float: right;
    }

    .title {
      margin-bottom: 10px;
    }
    .desc {
      margin-bottom: 30px;
    }
  }

  &__download {
    ul {
      display: inline-block;
      max-width: 300px;
      margin: auto;

      li {
        list-style: none;

        i {
          margin-right: 10px;
        }
      }
    }
  }
}

.is-arkilab {
  .ar-list {
    padding: 0;
  }
  //bioedilizia
  .cat-28778 {
    .btn--link-tr:before {
      background-color: #88AF5C;
    }
  }
  //portfolio
  .cat-28779 {
    .btn--link-tr:before {
      background-color: #367CBB;
    }
  }
  //progettazione
  .cat-28780 {
    .btn--link-tr:before {
      background-color: #E36A4D;
    }
  }
}