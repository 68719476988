.home-counters {
  padding-block: 32px;

  @media screen and (min-width: 768px) {
    padding-block: 64px;
  }

  h2 {
    font-size: var(--fs-3);
    line-height: 1.2;
    font-weight: 600;
    text-wrap: balance;
    margin-block-end: 20px;

    @media screen and (min-width: 768px) {
      margin-block-end: 50px;
    }
  }

  .counter {
    display: grid;
    gap: 20px;
    border-block-start: 1px solid var(--color-medium-grey);
    padding-block: 20px;
    font-weight: 600;

    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 3fr;
      align-items: center;
      gap: 0;
      padding-block: 50px;
    }

    &:last-child {
      border-block-end: 1px solid var(--color-medium-grey);
    }

    .number {
      font-size: var(--fs-5);
      line-height: 1.06;
    }

    .desc {
      font-size: var(--fs-1);
      text-wrap: pretty;
    }
  }
}