.arrow-link {
  color: var(--color-accent);
  font-size: 16px;
  line-height: 1.25;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 5px;
    aspect-ratio: 1/1;
    border-top: 1px solid var(--color-accent);
    border-right: 1px solid var(--color-accent);
    transform: rotate(45deg) translateY(-50%);
    right: -20px;
    top: 50%;
  }

  &:hover {
    text-decoration: none;
  }
}