.video {
  position: relative;
  height: 68vh;
  min-height: 500px;

  @media (max-width: 992px) {
    height: auto;
    min-height: inherit;
  }

  .wrap {
    position: relative;
  }

  &-title {
    max-width: 65%;
    margin-bottom: 150px;
    position: relative;
    z-index: 21;

    @media (max-width: 1024px) {
      font-size: 3rem;
      max-width: 80%;
    }

    @media (max-width: 992px) {
      max-width: 100%;
      margin-bottom: 0;
      padding: 0 1rem;
      font-size: 30px;
      position: absolute;
      top: 17vh;
      left: 0;
    }

    @media (max-width: 767px) {
      font-size: 27px;
      top: 105px;
    }
  }

  .col-video {
    height: 68vh;
    min-height: 600px;
    padding: 0 1rem;

    @media (max-width: 992px) {
      width: 100%;
      height: 48vh;
      min-height: inherit;
      margin-bottom: 50px;
    }

    @media (max-width: 767px) {
      height: 370px;
    }
  }

  .hi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /*span {
            color: #ff0;
            cursor: pointer;
            text-decoration: underline;
        }

        em {
            font-style: normal;

            &.hidden {
                display: none;
            }
        }*/
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 75vw;
    height: 100%;
    z-index: 11;
    background-color: rgba(black, 0.1);

    .link-video-full {
      display: none;
      position: absolute;
      top: 0px;
      left: 0;
      width: 100%;
      height: 100%;

      @media (max-width: 992px) {
        .full-video-open & {
          display: block;
        }
      }
    }

    .full-video-open & {
      z-index: 30;
    }

    @media (max-width: 992px) {
      width: 100vw;
    }
  }

  .full-video,
  .tv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    overflow: hidden;

    .screen {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      margin: auto;

      opacity: 0;
      transition: opacity 0.5s;

      &.active {
        opacity: 1;
      }
    }
  }

  .full-video {
    z-index: 23;
    opacity: 0;

    .is-editmode & {
      display: none;
    }

    &.visible {
      opacity: 1;
    }
  }
}

.video-loop {
  position: absolute;
  top: 0;
  left: 0;
  width: 75vw;
  height: 100%;
  background-color: var(--color-light-grey);

  @media (max-width: 992px) {
    width: 100vw;
    height: 52vh;
  }

  @media (max-width: 767px) {
    height: 400px;
  }
}

.video-pause,
.video-play {
  position: absolute;
  text-align: right;
  bottom: 100px;
  right: 1rem;
  cursor: pointer;
  display: none;

  @media (min-width: 992px) {
    max-width: 200px;
  }

  @media (max-height: 800px) {
    bottom: 200px;
  }

  @media (max-width: 992px) {
    text-align: left;
    position: static;
    right: auto;
    left: 0;
    z-index: 30;
  }

  i {
    font-size: 35px;
    color: $grey3;

    transition: color 0.25s ease-in-out 0s;

    @media (max-width: 992px) {
      color: white;
    }
  }

  &:hover i {
    color: var(--color-dark-grey);
  }

  &-text {
    color: black;
    font-family: var(--ff-standard);
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    clear: right;
    display: block;
    margin-top: 7px;

    @media (max-width: 992px) {
      display: inline;
      clear: left;

      position: relative;
      top: -5px;

      color: white;
    }
  }

  &.visible {
    display: inline-block;
  }
}

.slider-product {
  position: relative;
  z-index: 22;
  //height: 525px;

  @media (max-width: 1024px) {
    margin-top: -1rem;
  }

  @media (max-width: 992px) {
    height: auto;
  }

  .info {
    max-width: 315px;

    @media (max-width: 992px) {
      max-width: 240px;
    }

    @media (max-width: 767px) {
      max-width: inherit;
      margin-bottom: 2rem;
    }

    &:hover .btn--black {
      @extend .btn--black:hover;
    }
  }

  .info .type {
    color: var(--color-medium-grey);
    font-size: var(--fs-0);
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: calc(var(--fs-0) * 0.2);
    padding-bottom: 18px;
  }

  .info h2 {
    margin-bottom: 1.375rem;
    font-size: var(--fs-4);
    line-height: 1.18;
    font-weight: 600;
  }

  .info p {
    font-size: var(--fs-0);

    @media (max-width: 768px) {
      font-size: var(--fs-1);
    }
  }

  .slider-content {
    @media (max-width: 992px) {
      margin-top: 30px;
    }
  }

  /* New customization */
  .row.slide {
    visibility: hidden; 
    opacity: 0;
    background-color: white;
    transition: all .25s ease-in-out;

    img {
      visibility: hidden; 
      opacity: 0; 
      transform: matrix(1.1, 0, 0, 1.1, 0, 0);
      transition: all .25s ease-in-out;
    }

    .info > * {
      visibility: hidden!important; 
      opacity: 0!important; 
      transform: matrix(1, 0, 0, 1, 0, 0)!important;
      transition: all .25s ease-in-out;
    }
    
    &.active {
      visibility: inherit;
      opacity: 1;

      img {
        visibility: visible;
        opacity: 1;
        transform: matrix(1, 0, 0, 1, 0, 0);
      }

      .info > * {
        visibility: visible!important; 
        opacity: 1!important; 
        transform: matrix(1, 0, 0, 1, 0, 0)!important;
      }
    }
  }
}

.social-stream {
  padding: 50px 0;

  h4 {
    letter-spacing: 8px;
  }

  &__cont {
    margin-top: 2rem;
  }

  &__item {
    position: relative;
    display: block;

    @media (min-width: 576px) {
      min-height: calc(540px / 3);
    }

    @media (min-width: 768px) {
      min-height: calc(720px / 3);
    }

    @media (min-width: 1200px) {
      min-height: calc(960px / 3);
    }

    @media (max-width: 575px) {
      margin: 0 30px;
      margin-bottom: 30px;
      min-height: calc(100vw - 120px);
    }

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    > i {
      font-size: 28px;
      position: absolute;
      bottom: 13px;
      right: 17px;
      color: rgba(black, 0.4);
      //text-shadow: 0 0 15px rgba(0,0,0,.6);

      transition: color 0.25s linear 0s;
    }

    &:hover > i {
      color: rgba(black, 0.8);
    }
  }
}
