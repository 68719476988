
/*$h1-font-size:                4rem !default;
$h2-font-size:                3rem !default;
$h3-font-size:                2.25rem !default;
$h4-font-size:                1.75rem !default;
$h5-font-size:                1.563rem !default;
$h6-font-size:                1.375rem !default;*/

@media (max-width: 767px){
    h1, .h1 {
        font-size: 3rem;
    }
    h2, .h2 {
        font-size: 2.6rem;
    }
    h3, .h3 {
        font-size: 2rem;
    }
    h4, .h4 {
        font-size: 1.6rem;
    }
}