
.btn {
    font-size: var(--fs-0);
    text-transform: uppercase;
    padding: 15px 36px;
    line-height: 1;
    letter-spacing: calc(var(--fs-0) * 0.2);
    position: relative;
    isolation: isolate;
    overflow: hidden;

    @media screen and (max-width: 768px) {
        max-width: calc(100vw - 30px);
        padding: 15px;
    }

    &-black,
    &-primary,
    &-secondary,
    &-default {
        background-color: var(--color-dark-grey) !important;
        color: var(--color-light-grey) !important;
        border: none !important;

        &:hover {
            background-color: transparent !important;
            color: var(--color-dark-grey) !important;
            box-shadow: inset 0 0 0 2px currentColor;
        }
    }

    span {
        display: inline-block;
        translate: 0 0;
        transition: opacity .3s, translate .3s;
    }

    &::after {
        content: attr(data-text);
        position: absolute;
        inset: 0;
        text-transform: uppercase;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        translate: 0 25%;
        transition: opacity .3s, translate .3s;
    }

    &:hover {
        span {
            translate: 0 -25%;
            opacity: 0;
        }

        &::after {
            opacity: 1;
            translate: 0 0;
        }
    }
}

// Buttons
.btn--primary {
    @extend .btn;
    border: none;
    background-color: var(--color-accent);

    span {
        color: var(--color-dark-grey) !important;
    }

    &:hover {
        background-color: transparent;
        color: var(--color-dark-grey) !important;
        box-shadow: inset 0 0 0 2px currentColor;
    }

    .bg-dark &:hover {
        background-color: transparent;
        color: var(--color-accent) !important;
        box-shadow: inset 0 0 0 2px currentColor;
    }
}

.btn--black {
    @extend .btn;
    border: none;
    background-color: var(--color-dark-grey);

    span {
        color: var(--color-light-grey) !important;
    }

    &:hover {
        background-color: transparent;
        color: var(--color-dark-grey) !important;
        box-shadow: inset 0 0 0 2px currentColor;
    }
}

.btn--black-outlined {
    @extend .btn;
    border: none;
    background-color: transparent !important;
    color: var(--color-dark-grey) !important;
    box-shadow: inset 0 0 0 2px currentColor;

    &:hover {
        box-shadow: none;
        background-color: var(--color-dark-grey) !important;
        color: var(--color-light-grey) !important;
    }
}