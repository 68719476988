
// Base
@import "helpers/typo";
@import "vendors/froala/variables";
@import "base/default";

// Helpers
@import "helpers/helpers";
@import "helpers/mixins";
@import "helpers/placeholders";

// Vendors
@import "vendors/font-awesome-5/fontawesome";
@import "vendors/bootstrap/bootstrap";
@import "vendors/flickity";
//@import "vendors/pimcore5";
//@import "vendors/ytPlayer";

//@import "vendors/froala/buttons";
@import "vendors/froala/froala_blocks";
@import "vendors/froala/mixins";
@import "vendors/froala/teams";
@import "vendors/slick/slick";
@import "vendors/slick/slick-theme";
//@import "vendors/lightgallery/lightgallery";
@import "vendors/lightbox";

// Partials / Components
//@import "modules/headings";
//@import "modules/nav";
@import "modules/form";
@import "modules/megamenu";
@import "modules/spot";
@import "modules/buttons";
@import "modules/headings";
@import "modules/hero";
@import "modules/home-hero";
@import "modules/home-spot";
@import "modules/home-highlights";
@import "modules/home-banner";
@import "modules/home-counters";
@import "modules/fullwidth-text";
@import "modules/breadcrumbs";
@import "modules/filters";
@import "modules/slider";
@import "modules/carousel";
@import "modules/modal";
@import "modules/toolbox";
@import "modules/catalogo";
@import "modules/img-list";
@import "modules/file-cont";
@import "modules/cover";
@import "modules/tables";
@import "modules/wysiwyg";
@import "modules/linklist";
@import "modules/arrow-link";
@import "modules/product-focus";

// Layout
@import "layout/header";
@import "layout/content";
@import "layout/footer";

// Templates
@import "templates/home";
@import "templates/piazze";
@import "templates/prodotto";
@import "templates/members";
@import "templates/search";
@import "templates/arkilab";
@import "templates/contatti-commerciali";
@import "templates/progetto";

// Custom
@import "helpers/custom";
@import "helpers/editmode";
