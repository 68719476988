.intro-content {
    margin-top: 35px;

    p {
        font-size: 16px;
        line-height: 1.9;
        margin-bottom: 0;
    }
}

.list-spot {

    &__row {
        //margin-top: 50px;

        &:first-child {
            margin-top: 0;
            row-gap: 30px;
        }

        .spot-txt-img {
            //margin-bottom: 2rem;
            margin-bottom: 3.2rem;
        }

        .spot-cat {
            height: 100%;
            display: flex;
            flex-direction: column;

            .media-body {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .btn {
                    margin-top: auto;
                }
            }
        }
    }
    
    .no-results {
        display: none;
    }
    
}

.is-piazza {
    .media {
        width: 100%;
    }
}