.hero {
    height: 440px;
    padding-bottom: 56px;

    @media (max-width: 767px){
        height: 330px;
        padding-bottom: 0;
    }

    /*.is-content & {
        height: auto;
        padding-top: 200px;

        @media (max-width: 767px) {
            padding-top: 130px;
            padding-bottom: 34px;
        }
    }

    .is-product &,
    .is-piazza & {
        min-height: 80vh;

        @media (max-width: 992px) {
            min-height: 65vh;
        }

        @media (max-width: 767px) {
            height: auto;
            padding-bottom: 0;
            padding-top: 6.5rem;
            min-height: 250px;
        }
    }

    // koblenz product
    .is-koblenz.is-product & {
        height: auto!important;
        min-height: 40vh;
        padding-top: calc(140px + 2rem);
        padding-bottom: 0;

        @media (min-width: 992px){
            min-height: 55vh;
        }
    }*/

    .col {
        position: relative;

        /*.is-product & {
            @media (max-width: 992px) {
                padding-bottom: 2rem;
            }
        }*/
    }

    .col:before {
        content: '';

        position: absolute;
        bottom: -56px;

        width: 96%;
        height: 3px;

        @media (max-width: 767px){
            width: 100%;
            bottom: 0;
            left: 0;
        }

        .is-product & {
            bottom: 0;
        }
    }

    .media img {
        width: 123px;
        display: inline-block;

        .is-piazza & {
            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    .media-body {
        padding-top: 15px;

        @media (max-width: 767px) {
            padding-bottom: 2rem;
        }
    }

    .is-krona & {
        .col:before {
            background-color: var(--color-accent);
        }
    }

    .is-koblenz & {
        .col:before {
            background-color: var(--color-accent);
        }
    }

    .page-title {
        font-size: var(--fs-4);
        font-weight: 700;
        clear: left;
    }

    .page-description {
        font-size: var(--fs-1);
        font-family: var(--ff-standard);
        color: var(--color-dark-grey);
        // text-transform: lowercase;
        display: flex;
        align-items: center;

        .label {
            letter-spacing: 3px;
            text-transform: uppercase;
            font-size: var(--fs--1);
        }
    }

    .container {

        .is-product & {
            max-width: 720px !important;
        }

    }

    &__menu {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 1rem;
        padding-bottom: 20px;
        margin-top: 30px;
        border-bottom: 3px solid transparent;

        @media (min-width: 600px){
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            row-gap: 0;
        }

        .is-koblenz & {
            border-color: var(--color-accent);
        }

        .is-krona & {
            border-color: var(--color-accent);
        }

        /*.touch & {
            display: none;
        }*/

        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 10px;
            list-style: none;
            margin-bottom: 0;

            @media (min-width: 1400px){
                column-gap: 20px;
            }

            @supports not (column-gap: 20px){
                margin-right: 0px;
            }
        }

        ul li {
            display: inline-block;

            @supports not (column-gap: 20px){
                margin-right: 20px;

                &:last-child {
                    margin-right: 0px;
                }
            }

            &:last-child {
                margin-right: 0;
            }

            > a {
                font-size: var(--fs--1);
                font-weight: normal;
                line-height: 1.4;
                display: inline-block;
                letter-spacing: 1px;
                color: black;
                text-transform: uppercase;
                //max-width: 110px;

                @media (min-width: 1420px) {
                    // font-size: 14px;
                    max-width: inherit;
                }
            }
        }

        .btn {
            text-transform: uppercase;
        }

        // bottoni destra
        .buttons {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
            width: 100%;

            @media (min-width: 600px){
                width: auto;
                flex-direction: row;
            }

            > .btn--primary {
                padding-left: 1rem;
                padding-right: 1rem;
                // font-size: var(--fs--1);

                @media (min-width: 1420px) {
                    // font-size: 14px;
                }

                @media (max-width: 600px){
                    width: 100%;
                    display: inline-block;
                }
            }
        }

        // menu sinistra
        .menu-list {
            display: none;
            position: relative;
            //margin-right: 1rem;

            @media (min-width: 600px){
                display: flex;
            }

            ul {
                @media (max-width: 1220px){
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    background-color: #f0f0f0;

                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 15px;
                    border: 1px solid #ccc;

                    opacity: 0;
                    visibility: hidden;
                    transition: visibility .1s linear 0s, opacity .25s ease .1s;

                    &.is-open {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        } 
        .menu-list .fa-bars {
            font-family: var(--ff-standard);
            display: flex;
            align-items: center;
            column-gap: 5px;
            cursor: pointer;
            padding-top: 10px;

            &:before {
                font-family: 'Font Awesome 5 Pro';

                .is-active & {
                    content: '\f00d';
                }
            }

            @media (min-width: 1220px){
                display: none;
            }
        }
    }

    &.with-bg {
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,.4);
            z-index: 1;
        }

        .container {
            position: relative;
            z-index: 10;
        }

        .page-title,
        .breadcrumbs a,
        .breadcrumbs span,
        .page-description {
            color: white;
        }
    }
}

.touch .is-product .hero .page-description {
    @media (min-width: 992px){
        padding-bottom: 2rem;
    }
}

.is-content {
    .hero {
        height: auto;
        padding-top: 200px;

        @media (max-width: 767px) {
            padding-top: 130px;
            padding-bottom: 34px;
        }
    }
 }

.is-product,
.is-piazza {
    .hero.with-bg {
        min-height: 600px;
        height: auto;

        @media (max-width: 767px) {
            height: auto;
            padding-bottom: 0;
            padding-top: 6.5rem;
            min-height: 250px;
        }
    }
}

// koblenz product
.is-koblenz.is-product {
    .hero {
        height: auto!important;
        min-height: 40vh;
        padding-top: calc(140px + 2rem);
        padding-bottom: 0;

        @media (min-width: 992px){
            min-height: 55vh;
        }
    }
}

// Only hero product
.hero--product {
    .wrap {
        flex: 0 0 100%;
    }
    .col:before {
        display: none;
    }
}