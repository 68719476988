.site-header {
	color: var(--color-light-grey);
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 40;
	transition: translate .2s ease-in-out, background .3s linear;

	&:hover {
		background-color: var(--color-dark-grey) !important;
	}

	.is-landing &  {
		position: static;
	}

	.is-home &, .is-piazza-with-hero-img & {
		background: transparent;
	}

	@media screen and (min-width: 1200px) {
		padding-block-start: 65px;
	}

	&__brand {
		display: flex;
		align-items: center;
		padding: 24px 15px;
		width: 100%;

		img {
			height: 42px;
		}

		@media screen and (min-width: 1200px) {
			width: auto;
			padding-inline: 30px;
		}
	}

	.toggler {
		border: none;
		margin-left: auto;
		width: 32px;
		height: 20px;
		position: relative;
		background: none;
		padding: 0;
		outline: none;
		cursor: pointer;

		span,
		&::before,
		&::after {
			display: block;
			width: 100%;
			height: 2px;
			background-color: var(--color-light-grey);
			position: absolute;
			top: calc(50% - 1px);
		}

		span {
			transition: opacity 0.15s;
		}

		&::before,
		&::after {
			content: "";
		}

		&::before {
			top: 0;
			transition:
				top 0.15s linear 0.15s,
				rotate 0.15s ease-out;
		}

		&::after {
			top: auto;
			bottom: 0;
			transition:
				bottom 0.15s linear 0.15s,
				rotate 0.15s ease-out;
		}

		.menu-is-open & {
			span {
				opacity: 0;
			}

			&::before {
				top: calc(50% - 1px);
				rotate: 45deg;
				transition:
					top 0.15s linear,
					rotate 0.15s ease-out 0.15s;
			}

			&::after {
				bottom: calc(50% - 1px);
				rotate: -45deg;
				transition:
					bottom 0.15s linear,
					rotate 0.15s ease-out 0.15s;
			}
		}

		@media screen and (min-width: 1200px) {
			display: none;
		}
	}

	&__nav {
		display: grid;
		width: 100%;
		grid-template-rows: 0fr;
		transition: grid-template-rows 0.3s ease-in-out;
		max-height: calc(100svh - 90px);
		position: relative;

		@media screen and (min-width: 1200px) {
			width: auto;
			display: block;
			position: static;
		}

		.menu-is-open & {
			grid-template-rows: 1fr;
		}

		> * {
			overflow: auto;

			@media screen and (min-width: 1200px) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		span,
		a {
			color: var(--color-light-grey);

			&:hover {
				text-decoration: none;
				color: var(--color-accent);
			}
		}
	}

	&__primary-menu {
		font-size: var(--fs-1);

		@media screen and (min-width: 1200px) {
			font-size: var(--fs-0);
			display: flex;
			gap: 24px;
		}

		> li {
			padding: 15px;
			border-top: 1px solid var(--color-light-grey);

			@media screen and (min-width: 1200px) {
				padding: 34px 0;
				border: none;
			}


			> span {
				position: relative;
				cursor: pointer;

				@media screen and (min-width: 1200px) {
					padding-inline-end: 20px;
				}

				&::after {
					content: "";
					width: 6px;
					aspect-ratio: 1;
					display: block;
					position: absolute;
					border-left: 1px solid var(--color-light-grey);
					border-bottom: 1px solid var(--color-light-grey);
					rotate: -45deg;
					right: -20px;
					top: 50%;
					translate: 0 -50%;
					transition: rotate 0.3s ease-in-out;

					@media screen and (min-width: 1200px) {
						right: 2px;
					}
				}

				&[aria-expanded="true"]::after {
					rotate: -225deg;
				}
			}

			> span:hover::after {
				border-color: var(--color-accent);
			}

			> div {
				display: grid;
				grid-template-rows: 0fr;
				transition: grid-template-rows 0.3s ease-in-out;

				@media screen and (min-width: 1200px) {
					position: absolute;
					background-color: var(--color-dark-grey);
					top: 100%;
					left: 0;
					width: 100%;
					// border-top: 1px solid var(--color-light-grey);
					box-shadow: inset 0 1px 0 0  var(--color-light-grey);
				}


				&[aria-hidden="false"] {
					grid-template-rows: 1fr;
				}

				> * {
					overflow: hidden;

					@media screen and (min-width: 1200px) {
						display: grid;
						grid-template-areas: 
							"primary utils";
						grid-template-columns: 65% 35%;
					}

					li {
						padding-block: 8px;
						font-size: var(--fs-0);

						@media screen and (min-width: 1200px) {
							font-size: var(--fs-1);
						}

						&:last-child {
							padding-block-end: 0;

							@media screen and (min-width: 1200px) {
								padding-block-end: 8px;
							}
						}
					}
				}

				@media screen and (min-width: 1200px) {
					ul {
						padding: 30px;
						grid-area: primary;

						&.two-cols {
							column-count: 2;
						}
					}
				}

				@media screen and (min-width: 1440px) {
					ul {
						padding-inline-start: 8vw;
					}
				}
			}
		}
	}

	&__secondary-nav {
		padding-block-end: 55px;

		@media screen and (min-width: 1200px) {
			position: absolute;
			inset: 0 0 auto 0;
			padding: 0;
		}

		> ul:first-child {
			border-block-start: 1px solid var(--color-light-grey);

			@media screen and (min-width: 1200px) {
				border-block-start: none;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				height: 65px;
				border-block-end: 1px solid var(--color-light-grey);
			}

			> li {
				padding: 15px 15px 0;
				font-size: var(--fs-1);

				@media screen and (min-width: 1200px) {
					font-size: var(--fs--1);
					padding: 0 30px 0 0;
				}

				@media screen and (max-width: 1199px) {
					&:last-child {
						display: none;
					}
				}
			}
		}

		.form--search {
			margin-block-start: 48px;

			@media screen and (min-width: 1200px) {
				display: none;
			}
		}

		.lang-menu {
			display: flex;
			align-items: center;
			gap: 16px;
			padding-inline: 15px;
			margin-block: 30px;

			@media screen and (min-width: 1200px) {
				display: none;
			}

			> li {
				padding: 0;
			}

			span {
				text-decoration: underline;
				text-decoration-color: var(--color-accent);
				text-underline-offset: 4px;
			}
		}

		> a {
			i {
				font-size: var(--fs-2);
			}

			display: block;
			padding: 15px 15px 0;
			font-size: var(--fs-1);
		}
	}

	&__secondary-sub {
		border-top: 1px solid var(--color-light-grey);
		margin-block-start: 16px;
		padding-block-start: 8px;


		ul > li {
			font-size: var(--fs-1);
		}

		> .btn {
			display: block;
			position: absolute;
			bottom: 15px;
			width: calc(100% - 30px);
			visibility: hidden;
			opacity: 0;
			transition: opacity 0s ease-in-out .1s;

			.menu-is-open & {
				visibility: visible;
				opacity: 1;
			}

			@media screen and (min-width: 576px) {
				display: inline-block;
				width: auto;
			}

			@media screen and (min-width: 1200px) {
				margin-inline: 30px;
				margin-block-end: 30px;
				position: relative;
				width: auto;
				visibility: visible;
				opacity: 1;
			}
		}

		@media screen and (min-width: 1200px) {
			grid-area: utils;
			border-inline-start: 1px solid var(--color-light-grey);
			padding: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin: 0;
			border-block-start: none;

			> ul {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				margin-block-end: 200px;

				> li {
					padding-inline: 0;
				}

				> li:last-child {
					margin-block-start: auto;
				}
			}
		}

		@media screen and (min-width: 1440px) {
			> ul {
				padding-inline-start: 30px !important;
			}
		}
	}

	@media screen and (min-width: 1200px) {
		&__utils {
			margin-inline-start: auto;
			align-items: center;
			gap: 24px;
			padding-inline: 30px;

			.lang-menu {
				list-style: none;
				margin: 0;

				ul {
					list-style: none;
				}

				> li{
					position: relative;

					> span {
						padding-inline-end: 20px;
						cursor: pointer;

						&::after {
							content: '';
							width: 6px;
							aspect-ratio: 1;
							display: block;
							position: absolute;
							border-left: 1px solid var(--color-light-grey);
							border-bottom: 1px solid var(--color-light-grey);
							rotate: -45deg;
							right: 2px;
							top: 50%;
							translate: 0 -50%;
							transition: rotate 0.3s ease-in-out;
						}
					}

					> div {
						position: absolute;
						top: 100%;
						left: -8px;
						display: grid;
						grid-template-rows: 0fr;
						background-color: var(--color-dark-grey);
						width: max-content;
						transition: grid-template-rows .3s ease-in-out;

						> * {
							overflow-y: hidden;

							li {
								padding-inline: 8px;
								
								a {
									color: var(--color-light-grey);
								}

								@media screen and(hover: hover) {
									a:hover {
										text-decoration: none;
										color: var(--color-accent);
									}
								}

								&:last-child {
									padding-bottom: 4px;
								}
							}
						}
					}
				}

				> li:hover {
					> div {
						grid-template-rows: 1fr;
					}
				}
			}

			> a {
				color: var(--color-light-grey);
				font-size: var(--fs-1);
			}

			@media screen and(hover: hover) {
				a:hover {
					text-decoration: none;
					color: var(--color-accent);
				}
			}
		}
	}

	&--hidden {
		translate: 0 -100%;
	}

	&--landing {
		padding-top: 0;
		
		.site-header__brand {
			margin-inline: auto;
		}
	}
}

