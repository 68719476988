.home-highlights {
  padding-block: 32px;

  @media screen and (min-width: 768px) {
    padding-block: 64px;
  }

  &__grid {
    display: grid;
    gap: 20px;

    @media screen and (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (min-width: 992px) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__main,
  &__block {
    padding: 15px;

    @media screen and (min-width: 768px) {
      padding: 30px;
    }

    h3 {
      font-size: var(--fs-3);
      font-weight: 600;
      line-height: 1.15;

      em {
        color: var(--color-accent);
      }
    }

    p {
      margin: 0;
      font-size: var(--fs-0);
    }
  }

  &__main {
    @media screen and (min-width: 768px) {
      grid-column: 1/-1;
    }
    @media screen and (min-width: 992px) {
      grid-column: auto;
      grid-row: 1/-1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .arrow-link {
      margin-block-start: 16px;
      display: inline-block;

      @media screen and (min-width: 992px) {
        margin-top: auto;
      }
    }
  }
}