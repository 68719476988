.product-focus {
  .label {
    text-align: end;
    margin-bottom: 20px;
    font-size: var(--fs-0);
    line-height: 1;
    letter-spacing: calc(var(--fs-0) * 0.2);
    text-transform: uppercase;
  }

  header {
    max-width: 65ch;
    margin-inline: auto;
    margin-block-end: 20px;

    h2 {
      max-width: 65ch;
      font-size: var(--fs-3);
      line-height: 1.16;
      font-weight: 600;
      text-align: center;
    }
  }

  &__content {
    display: grid;
    gap: 30px;
  }

  .video {
    height: auto;
    aspect-ratio: 16/9;
    position: relative;

    iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }
  }

  .spot-grid {
    --min-w: 360px;
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(var(--min-w), 1fr));

    &--3cols {
      --min-w: 320px;
    }

    .spot {
      h4 {
        font-size: var(--fs-1);
        word-break: break-word;
        font-weight: 600;
      }
    }
  }

  .image {
    img {
      display: block;
      margin-inline: auto;
    }
  }

  ul.focus {
    padding-inline-start: 12px;

    @media screen and (min-width: 768px) {
      column-count: 2;
      column-gap: 30px;
    }

    li {
      margin-block-end: 8px;

      &::marker {
        content: '\2713';
      }
    }
  }
}
