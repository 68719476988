
.wrap {
    max-width: 1440px;
    margin: 0 auto;

    &--small,
    &__small {
        max-width: 720px;
        margin: 0 auto;
    }

    &--medium {
        max-width: 960px;
        margin: 0 auto;
    }
}

.site-content {
    min-height: 50vh;

    &.tpl-members {
        padding: 15vh 0;
    }

    .content {
        padding: 42px 0;
        min-height: 40vh;

        .is-piazza & {
            padding: 60px 0;
        }
    }
}