@mixin box-sizing($sizing) {
  box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  -webkit-box-sizing: $sizing;
}

@mixin border-radius($radius) {
  border-radius: $radius;
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
}

@mixin box-sizing($val) {
  box-sizing: $val;
  -moz-box-sizing: $val;
  -webkit-box-sizing: $val;
}

@mixin box-shadow ($shadow: none) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow:    $shadow;
	box-shadow:         $shadow;
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing:   antialiased;
    -moz-osx-font-smoothing:  grayscale;
  }

  @else {
    -webkit-font-smoothing:   subpixel-antialiased;
    -moz-osx-font-smoothing:  auto;
  }
}

@mixin display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin froala-box-shadow ($level: 1, $direction: 1) {
  $shadow: none;

  @if $level == 1 {
    $shadow: 0 ($direction * 1px) 3px rgba(0,0,0,0.12), 0 ($direction * 1px) 2px rgba(0,0,0,0.24);
  }
  @else if $level == 2 {
    $shadow: 0 ($direction * 3px) 6px rgba(0,0,0,0.16), 0 ($direction * 2px) 4px rgba(0,0,0,0.23);
  }
  @else if $level == 3 {
    $shadow: 0 ($direction * 5px) 8px rgba(0,0,0,0.19), 0 ($direction * 4px) 6px rgba(0,0,0,0.23);
  }
  @else if $level == 4 {
    $shadow: 0 ($direction * 8px) 12px rgba(0,0,0,0.25), 0 ($direction * 6px) 6px rgba(0,0,0,0.22);
  }
  @else if $level >= 5 {
    $shadow: 0 ($direction * 10px) 16px rgba(0,0,0,0.30), 0 ($direction * 6px) 8px rgba(0,0,0,0.22);
  }

  @include box-shadow($shadow);
}
