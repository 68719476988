input[type="checkbox"].form-control,
input[type="radio"].form-control {
	width: auto;
}

select:-moz-focusring {
	color: transparent;
	text-shadow: 0 0 0 #000;
}

.form {
	&-control {
		border-radius: 0;
		padding: 9px 15px;
		border-width: 2px;
		border-color: var(--color-medium-grey);

		&:focus {
			border-color: var(--color-accent);
			box-shadow: none;
		}
	}

	&--search {
		border-block-end: 1px solid var(--color-light-grey);
		font-size: var(--fs-1);

		input[type="text"] {
			flex-grow: 1;
			background-color: transparent;
			padding: 10px 15px;
			outline: none;
			border: none;
			color: inherit;
		}

		button {
			padding: 10px 15px;
			background-color: transparent;
			outline: none;
			border: none;
			color: currentColor;
		}
	}

	&--newsletter {
		max-width: 510px;
		margin-left: auto;
		margin-right: auto;

		@media (max-width: 767px) {
			max-width: 80%;
		}

		input {
			//opacity: 0.09;
			background-color: rgba(255, 255, 255, 0.09);
			border: none;
			color: white !important;

			@media (max-width: 767px) {
				text-align: center;
			}

			&:focus {
				background-color: rgba(255, 255, 255, 0.09);
				outline: none;
			}
		}

		input::placeholder {
			color: white;
			//opacity: 1;
		}

		input,
		button {
			font-size: 0.9rem !important;
		}

		.btn {
			letter-spacing: 0;
		}

		@media (max-width: 767px) {
			.input-group-append,
			.input-group-append button {
				width: 100%;
			}
		}
	}

	&--default {
		.form-control {
			margin-bottom: 1rem;
		}

		.privacy {
			font-size: 11px;

			a {
				font-weight: bold;
				color: black;
				text-decoration: underline;
			}

			label {
				margin-right: 30px;
			}

			input[type="radio"] {
				margin-right: 10px;
			}
		}
	}
}

.form-control::placeholder {
	color: rgba(var(--color-dark-grey), 0.4);
}

form label {
	margin-bottom: 0.2rem;
	font-family: var(--ff-standard);
	font-weight: 600;
}

.form-group {
	label.required {
		content: "*";
	}

	&.small-size {
		margin-bottom: 0;

		p {
			font-size: 14px;

			&:first-child {
				margin-bottom: 0;
			}
		}
	}
}

// Select
form {
	select {
		padding: 9px 15px;
		font-size: 16px;
		border: 1px solid #ced4da;
		//width: 150px;
		height: 44px !important;

		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		position: relative;

		&::after {
			font-family: "Font Awesome 5 Pro";
			content: "\f107";
			position: absolute;
			right: 0;
			width: 30px;
			height: 30px;
			color: $black;
		}
	}
	.select {
		cursor: pointer;
		display: inline-block;
		position: relative;
		//font:normal 11px/22px Arial,Sans-Serif;
		border: 1px solid #ced4da;
		width: 100%;
	}

	.g-recaptcha {
		margin-bottom: 30px;
	}
}

.check-newsletter {
	position: relative;
	margin-top: 15px;

	.control-label {
		position: absolute;
		top: -5px;
		left: 30px;
	}
}

.text-privacy {
	font-size: 14px;
}
.radio-privacy {
	margin-bottom: 2rem;

	input[type="radio"] {
		margin-right: 10px;
	}

	label {
		margin-right: 20px;
	}
}

// Custom select for filters
.ext-custom-select {
	display: inline-block;
	position: relative;
	//width: 200px;
	//padding: 0 15px;
	margin-right: 15px;
	border: 1px solid $grey3;
	overflow: hidden;

	@media (max-width: 540px) {
		width: 100%;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;

		background: none;
		border-radius: 0;
		border: none;

		height: 44px;
		line-height: 44px;
		padding: 0 15px;

		outline: none;

		@media (max-width: 540px) {
			width: 100%;
		}

		&:focus,
		&:active {
			border: none;
		}
	}

	select option {
		//color: $grey3;
		width: 80%;
		outline: none;

		&:checked {
			color: $black;
		}
	}

	&::after {
		font-family: "Font Awesome 5 Pro";
		content: "\f107";
		position: absolute;
		top: 0;
		right: 15px;
		width: auto;
		height: 44px;
		line-height: 44px;
		color: $grey3;
	}
}

.form-group {
	&.has-error {
		input {
			border-color: $red;
		}

		.help-block {
			display: none;
		}
	}
}

.form-check {
	display: inline-block;
}

.form-contatti-commerciali {
	display: grid;
	gap: 1rem;

	@media screen and (min-width: 768px) {
		grid-template-columns: repeat(3, 1fr);
	}

	.field {
		label {
			display: block;
			font-weight: 400;
			font-size: var(--fs--1);
			text-transform: uppercase;
		}

		.select {
			border: none;
			position: relative;

			&::after {
				content: '';
				position: absolute;
				pointer-events: none;
				inset: 14px 18px auto auto;
				width: 10px;
				height: 10px;
				border-bottom: 2px solid black;
				border-left: 2px solid black;
				rotate: -45deg;
			}

			select {
				width: 100%;
				background-color: white;
			}
		}

		&:nth-child(2) {
			option:not(:disabled) {
				display: none;
			}
		}

		&:last-child {
			display: none;
		}
	}
}