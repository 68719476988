
.search-content {

    margin-bottom: 4rem;

    .search-form {
        margin-bottom: 2rem;
        justify-content: left;

        @media (min-width: 767px){
            input[type="text"] {
                min-width: 400px;
            }
        }

    }

    .media {
        margin-bottom: 2rem;

        &:last-child {
            margin-bottom: 0;
        }

        h4 a {
            color: var(--color-dark-grey);
        }
    }


}