:root {
    --color-bg-hero: var(--color-light-grey);
    --color-text-data: var(--color-dark-grey);
    --color-text-strong-data: var(--color-medium-grey);
}

.product {
    &__section {
        width: 50%;
        float: left;

        &.info-text {
            @media (max-width: 1200px) {
                width: 55%;
            }
        }

        &.info-text .content {
            min-height: 50vh;
        }

        &.gallery {
            position: fixed;
            top: 0;
            right: 0;
            z-index: 30;
            height: 100vh;
            background-color: var(--color-dark-grey);
            z-index: 30;
        }

        &.custom-content,
        &.gallery {
            @media (max-width: 1200px) {
                width: 45%;
            }
        }

        .carousel__logo {
            position: absolute;
            bottom: 40px;
            left: 40px;

            /*padding: 10px;
            background-color: white;*/
        }

        .carousel__logo img {
            max-width: 140px;

            @media (max-width: 767px){
                max-width: 100px;
            }
        }

        &.custom-content .col {
            margin-top: -250px;

            @media (max-width: 1200px) {
                margin-top: -180px;
            }

            .is-cerniera & {
                @media (max-width: 992px) {
                    margin-top: 0;
                }
            }
        }

        &.custom-content img {
            margin-bottom: 2rem;

            .is-koblenz & {
                border: 1px solid var(--color-dark-grey);
            }
        }

        @media (max-width: 992px) {
            width: 100% !important;
        }
    }

    &__info {

        @media (max-width: 767px) {
            text-align: center;
        }

        h5 {
            margin-bottom: 10px;

            @media (max-width: 767px) {
                margin-bottom: 40px;
            }

            .is-koblenz & {
                margin-bottom: 10px;

                @media (max-width: 767px) {
                    margin-bottom: 20px;
                }
            }
        }
        
        // Bottoni presenti nella sezione solo mobile
        .btn {
            width: 100%;

            @media (min-width: 600px){
                width: auto;
            }
        }
    }

    &__data {
        margin-top: 20px;

        /*span {
            display: block;
            font-size: 14px;
            line-height: 2;
            color: var(--color-hero);

            strong {
                text-transform: uppercase;
            }
        }*/

        p {
            color: var(--color-text-data);
            font-size: var(--fs-0);
            margin-bottom: 20px;            
        }
        p > strong {
            color: var(--color-text-strong-data);
            text-transform: uppercase;
        }   
    }

    &__video {
        margin-top: 30px;

        .embed-responsive {
            margin-bottom: 30px;
        }
    }

    &__slider {
        margin-bottom: 30px;

        // 
        .slider-for > .slick-list {
            border: 1px solid #CACACA;
        }

        .slider {     
            height: auto;

            .slick-arrow {
                z-index: 10;
            }

            .slick-prev,
            .slick-next {
                &:before {
                    content: '';
                    display: inline-block;
                    font-family: 'Font Awesome 5 Pro';
                }
            }

            .slick-prev::before {
                content: '\f053';
            }
            .slick-next::before {
                content: '\f054';
            }

            img {
                width: auto;
                max-width: 100%;
            }
        }

        .slider-for {
            .slick-prev,
            .slick-next {
                top: auto;
                bottom: 45px;
                z-index: 10;

                display: flex;
                align-items: center;
                justify-content: center;

                width: 40px;
                height: 40px;
                background-color: rgba(0,0,0,.75);

                // Mobile
                @media (max-width: 992px){
                    bottom: 0;
                }
                
                &:before {
                    display: inline-block;
                    font-size: var(--fs-1);
                    color: white;
                }
            }
            .slick-prev {
                left: auto;
                right: 75px;

                @media (max-width: 992px){
                    right: 60px;
                }

                &:before {
                    content: '\f177';
                }
            }
            .slick-next {
                left: auto;
                right: 30px;

                @media (max-width: 992px){
                    right: 15px;
                }

                &:before {
                    content: '\f178';
                }
            }
        }

        .slider-nav {
            text-align: center;
            margin-top: 20px;

            img {
                //width: 157.5px!important;
                max-width: 100%;
                width: auto;
                height: auto;
                margin: 0 7.5px;
                cursor: pointer;
                border: 1px solid #CACACA;

                @media (min-width: 992px){
                    margin: 0 15px;
                }
            }
            .slick-prev {
                left: 0;
            }
            .slick-next {
                right: 0;
            }
        }
    }
}

.product-field {
    &__item {
        margin-bottom: 60px;

        &.bg-gray {
            padding: 30px;
        }

        h3,h4 {
            margin-bottom: 30px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.plus {
    ul {
        list-style: none;
        margin: 1rem 0;
    }

    ul > li {
        position: relative;
        padding-left: 20px;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &:before {
            font-family: "Font Awesome 5 Pro";
            font-weight: bold;
            font-size: var(--fs--1);
            content: '\f067';

            position: absolute;
            top: 5px;
            left: 0;

            .is-krona & {
                color: var(--color-accent);
            }

            .is-koblenz & {
                color: var(--color-accent);
            }

        }
    }

    // testo descrittivo
    h5 {
        margin-top: 1rem;
        display: inline-block;
    }

}

.flag img {
    width: 30px;
}

.flag span {
    font-size: var(--fs--1);
    font-weight: bold;
    letter-spacing: 1.8px;
    color: black;
    margin-left: 10px;
}

.product__section {

    .content h5 {
        margin-bottom: 20px;
    }

    .box {
        background-color: var(--color-light-grey);
        padding: 20px;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;
        float: left;

        @media (max-width: 480px) {
            margin-right: 0;
            width: 100%;
        }

        &.open-video {
            cursor: pointer;
            transition: background .25s ease 0s;
        }

        &.open-video:hover {
            background-color: $grey2;
        }

        &.open-video {
            min-height: 118px;
        }
    }

    .accessori .box:hover,
    .correlati .box:hover {
        background-color: $grey2;
    }

    .videos .box {
        min-height: 118px;
    }

    .box i {
        font-size: var(--fs-2);
        position: absolute;
        bottom: 23px;
        right: 23px;
        color: $grey3;
    }

    .box h6 {
        color: var(--color-dark-grey);
        line-height: 1.48;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .box p {
        font-size: var(--fs--1);
        margin-bottom: 0;
        color: var(--color-dark-grey);
        max-width: 180px;
    }

    .docs {
        .files {
            margin-bottom: 42px;

            .article {
                font-weight: 400;
                color: var(--color-dark-grey);
            }

            .type {
                display: block;
                font-weight: 600;
                font-size: var(--fs--1);
                text-transform: uppercase;
                margin-bottom: 18px;
                color: var(--color-dark-grey);
            }

            a {
                color: var(--color-dark-grey);
                margin-right: 50px;
                margin-bottom: 1.1rem;
                cursor: pointer;
                display: block;
                vertical-align: top;
                word-break: break-all;

                @media (max-width: 767px) {
                    margin-right: 0;
                }

                &.single {
                    margin-bottom: 10px;
                }

                > i {
                    margin-right: 10px;
                    color: var(--color-light-grey);

                    &.fa-minus,
                    &.fa-plus {
                        font-size: var(--fs--1);
                    }
                }

                &.locked {
                    opacity: .5;

                    > i {
                        color: var(--color-dark-grey);
                    }
                }

                &:hover {
                    text-decoration: underline;

                    > i {
                        color: var(--color-dark-grey);
                    }
                }
            }

            a[aria-expanded=false] {
                .fa-plus {
                    display: inline-block;
                }

                .fa-minus {
                    display: none;
                }
            }

            [aria-expanded=true] {
                .fa-plus {
                    display: none;
                }

                .fa-minus {
                    display: inline-block;
                }
            }

            // List file (info + disegni)
            &__list {
                list-style: none;
                padding-left: 0;
                margin-left: 15px;
                margin-bottom: 15px;

                .type {
                    margin-bottom: 10px;
                }

                li > a {
                    margin-bottom: 5px;
                    font-size: var(--fs--1);

                    &:hover {
                        text-decoration: none;
                    }
                }

                /*li:last-child > a {
                    margin-bottom: 0;
                }*/
            }

        }
        .modal {
            .modal-header {
                padding: 0;
                margin-bottom: 30px;
            }
            iframe {
                width: 100%;
                height: 70vh;
                border: none;
            }
        }
    }

    .description {

        .is-koblenz & {

            table {
                margin-bottom: 2rem;
            }
            table br {
                display: none;
            }
            table img {
                vertical-align: middle;
                max-width: 200px;
            }
            table td {
                border: 1px solid var(--color-light-grey);
                border-collapse: collapse;
                margin: 0!important;
                padding: 5px 10px;
            }
            table tr td:first-child {
                background-color: var(--color-light-grey);
            }

            h2 {
                @extend .h3;
            }

            h3 {
                @extend .h4;
            }

            /*h4 {
                @extend .h5;
            }

            h5 {
                @extend .h6;
            }

            h6 {
                font-size: 16px;
            }*/

            p img {
                //border: 1px solid $grey2;
            }

            hr {
                display: none;
            }

        }
    }

    .kit {
        h2 {
            @extend .h3;
        }

        h3 {
            @extend .h4;
        }
    }

    .kit ul {
        padding-left: 15px;
    }

    .custom-content {

        hr {
            display: none;
        }

        @media (max-width: 992px){
            img {
                border: 1px solid var(--color-light-grey);
                margin-bottom: 2rem;
            }
        }
    }
}

// Logo mobile su carosello prodotto
.cont-for-logo {
    position: relative;
    overflow: hidden;

    .carousel__logo {
        position: absolute;
        bottom: 30px;
        left: 30px;

        @media (max-width: 576px) {
            bottom: 15px;
            left: 15px;
        }
    }
}

.hero-extras {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @media (min-width: 992px) {
        flex-direction: row;
    }

    .page-title {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;

        @media (min-width: 992px) {
            flex-direction: row;
        }
    }
}

.hero-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 1rem;
    //row-gap: 15px;

    @media (min-width: 992px){
        //column-gap: 15px;
        margin-top: 0;
    }

    &__badge {
        @extend .spot-txt-img__badge;

        position: static;
        font-size: var(--fs--1);
        margin-left: 10px;
        margin-top: 10px;

        @media (min-width: 992px){
            margin-top: 0;
            margin-left: 15px;
        }

        &.out-of-stock {
            background-color: var(--color-accent);
        }
    }
}

.hero-info {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: flex-start;

    @media (min-width: 992px){
        align-items: flex-end;
    }

    .flag > img {
        width: 20px;
        height: auto;       
    }
    .flag > span {
        font-size: var(--fs--1);
        margin-left: 5px;
    }
}

// Editmode
.list-product-editmode {
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    > * {
        padding: 0 15px;
        flex: 0 0 33.333%;
        max-width: 33.333%;
    }

    .pimcore_editable > div {
        width: 100%!important;
    }
}

// Old product layout
.title-badge {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    @media (min-width: 992px){
        flex-direction: row;
    }
}